const skillsData = [
    {
        category: 'Mobile App Development',
        skills: [
            { name: 'Flutter', level: 100, logo: 'flutter.png' },
            { name: 'React Native', level: 100, logo: 'react.png' },
            { name: 'Java/Kotlin', level: 70, logo: 'android.png' },
            { name: 'Ionic', level: 30, logo: 'ionic.png' },
        ],
    },
    {
        category: 'Back-end Development',
        skills: [
            { name: 'React', level: 100, logo: 'react.png' },
            { name: 'Flutter', level: 100, logo: 'flutter.png' },
            { name: 'NextS', level: 100, logo: 'nextjs.png' },
            { name: 'VueJS', level: 100, logo: 'vuejs.png' },
            { name: 'Java Spring', level: 100, logo: 'spring.png' },
            { name: 'PHP', level: 100, logo: 'php.png' },
            { name: '.Net', level: 70, logo: 'net.png' },
            { name: 'Ionic', level: 30, logo: 'ionic.png' },
        ],
    },
    {
        category: 'Front-End Development',
        skills: [
            { name: 'Flutter', level: 100, logo: 'flutter.png' },
            { name: 'React Three Fiber', level: 100, logo: 'r3f.png' },
            { name: 'React', level: 100, logo: 'react.png' },
            { name: 'VueJs', level: 100, logo: 'vuejs.png' },
            { name: 'CSS', level: 70, logo: 'css.png' },
            { name: 'Angular', level: 70, logo: 'angular.png' },
            { name: 'Ionic', level: 30, logo: 'ionic.png' },
        ],
    },
    {
        category: 'Embedded Programming',
        skills: [
            { name: 'Arduino', level: 100, logo: 'arduino.png' },
            { name: 'Python', level: 100, logo: 'python.png' },
            { name: 'C', level: 30, logo: 'C.png' },
        ],
    },
    {
        category: 'Data ,AI and Machine Learning',
        skills: [
            { name: 'SQL', level: 100, logo: 'sql.png' },
            { name: 'Google Recommendation Engine', level: 100, logo: 'google.png' },
            { name: 'Data Warehousing', level: 70, logo: 'data.png' },
            { name: 'Searching Algorithms', level: 70, logo: 'search.png' },
        ],
    },
    {
        category: 'Desktop App Dev.',
        skills: [
            { name: 'Flutter', level: 100, logo: 'flutter.png' },
            { name: 'C#', level: 70, logo: 'csharp.png' },
        ],
    },
];

export default skillsData;
