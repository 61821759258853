import logo from './logo.svg';
import './App.css';
import './Views/Main/Main';
import {Main} from "./Views/Main/Main";
import AnimatedCursor from "react-animated-cursor";
import {BrowserRouter, Router} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {useEffect, useState} from "react";



function App() {

const [isVideoShown,setIsVideoShown]=useState(false);

    // document.addEventListener('DOMContentLoaded', function() {
    //     const video = document.getElementById('bgvid');
    //    if(isVideoShown){
    //        video.play();
    //    }
    // });
    useEffect(() => {
        // Check if device supports touch events
        if ('ontouchstart' in window || window.DocumentTouch && document instanceof window.DocumentTouch) {
            const cursorElem = document.querySelector('.cursor');
            if (cursorElem) cursorElem.style.display = 'none';
        }
    }, []);
    Sentry.init({
        dsn: "https://6589d1612d0410ebc98ce8ed71674ada@o4505992076591104.ingest.sentry.io/4505992079605760",
        integrations: [new Sentry.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    });

    const transaction = Sentry.startTransaction({ name: "test-transaction" });
    const span = transaction.startChild({ op: "functionX" }); // This function returns a Span
// functionCallX
    span.finish(); // Remember that only finished spans will be sent with the transaction
    transaction.finish(); // Finish

    return (
  <div className={"App wrapper"}>
      <div className={'cursor'}>
          <AnimatedCursor
              trailingSpeed={4}
              innerSize={20}
              outerSize={35}
              innerScale={1}
              outerScale={2}
              outerAlpha={1}
              hasBlendMode={true}


              innerStyle={{

                  backgroundColor: '#f2a400'
              }}
              outerStyle={{
                  mixBlendMode: 'exclusion',

                  backgroundColor: 'rgb(14,117,143)'
              }}
          />
      </div>

      {isVideoShown ?  <video playsInline autoPlay muted loop id="bgvid">
          <source src="edited.mp4" type="video/mp4"/>
      </video> : <div className={'loading-animation-container'}>

      </div>}
<BrowserRouter>
    <Main setVideoShown={setIsVideoShown}/>
</BrowserRouter>



  </div>



  );
}

export default Sentry.withProfiler(App);
