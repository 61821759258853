
 export const projects = [
     {
         projectNumber: 1,
         threeD:"https://prod.spline.design/oa2sB0PKITkngh3s/scene.splinecode",
         projectName: 'VOYAGE',
         purpose: 'Trip Planning and Places Recommending',
         technologies: ['Flutter','NextJS','Google Recommendation Engine','Google Maps API','Google Analysis','Google Firebase','MongoDB'],
         courseName: 'Senior Project',
         buttonLink: 'https://github.com/onurural/voyage'
     },
    {
        projectNumber: 2,
        threeD:"https://prod.spline.design/sl7y7mwI2jvfwgJI/scene.splinecode",
        projectName: 'QUESTION WORLD',
        purpose: 'Posting Question for High-School Students',
        technologies: ['Flutter','Google Firebase'],
        courseName: 'Mobile Application Development',
        buttonLink: 'https://github.com/mtarikg/QuestionWorld'

    },
    {
        projectNumber: 3,
        threeD:"https://prod.spline.design/F-jsLfHpEZ1F10iZ/scene.splinecode",
        projectName: 'BOARD GAME',
        purpose: 'Solving Board Game Using A* Algorithm',
        technologies: ['Python'],
        courseName: 'Artificial Intelligence and Expert Systems',
        buttonLink: 'https://github.com/onurural/BoardGame'

    },
     {
         projectNumber: 4,
         threeD:"https://prod.spline.design/9d2JeeNo-2jQTsMG/scene.splinecode",
         projectName: 'PETCARE',
         purpose: 'Adopting Pets From The Shelters',
         technologies: ['Flutter'],
         courseName: 'Software Project Management',
         buttonLink: 'https://github.com/serhatuzunbayir/PetCare'

     },
     {
         projectNumber: 5,
         threeD:"https://prod.spline.design/0FGed7wE7AOP7m9C/scene.splinecode",
         projectName: 'WASTE COLLECTION MANAGEMENT',
         purpose: 'Software Architecture Design Patterns',
         technologies: ['Java'],
         courseName: 'Software Architecture',
         buttonLink: 'https://github.com/obadaqasem/Waste_Collection_System'

     },


];