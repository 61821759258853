import {motion} from 'framer-motion';

export const HintBox = ({ onClose, message }) => {
    const boxVariants = {
        hidden: { opacity: 0, scale: 0.7, x: 100 },
        visible: { opacity: 1, scale: 1, x: 0, transition: { type: 'spring', stiffness: 260, damping: 20 } },
        exit: { opacity: 0, scale: 0.9, x: -100, transition: { duration: 0.5 } }
    };

    const buttonVariants = {
        hover: { scale: 1.1, textShadow: "0px 0px 8px rgb(255,255,255)", boxShadow: "0px 0px 8px rgb(255,255,255)" }
    };

    return (
        <motion.div
            className="hint-box normal-font"
            variants={boxVariants}
            initial='hidden'
            animate='visible'
            exit='exit'
        >
            <p>{message}</p>
            <motion.button
                onClick={onClose}
                className="close-btn"
                variants={buttonVariants}
                whileHover="hover"
            >
                Close
            </motion.button>
        </motion.div>
    );
};
