import React, {useState, Suspense, useEffect, useRef} from 'react';
import { useWheel} from 'react-use-gesture';
import skills from "../../Data/Skills";
import {projects} from "../../Data/Projects";
import '../../dist/styles.css';
// import './Styles/main.scss'
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {AnimatePresence, motion} from 'framer-motion';
import {HintBox} from "./HintBox";



const IntroAvatar = React.lazy(() => import("../IntroAvatar/IntroAvatar"));

const Intro = React.lazy(() => import('../Intro/Intro'));
const Education = React.lazy(() => import('../Education/Education'));
const LanguagesView = React.lazy(() => import('../LanguagesView/LanguagesView'));
const WorkExperiences = React.lazy(() => import('../Experiences/Styles/WorkExperiences'));
const ProjectCardsList = React.lazy(() => import('../Projects/ProjectsCardsList'));
const SkillPortfolio = React.lazy(() => import('../SkillsView/SkillPortfolio'));
const ContactForm = React.lazy(() => import('../ContactForm/ContactForm'));
const Navigation= React.lazy(() => import('./Navigation'));

export const Context = React.createContext({});

// create the provider
const Provider = (props) => {
    const [menuOpenState, setMenuOpenState] = useState(false)

    return (
        <Context.Provider value={{
            isMenuOpen: menuOpenState,
            toggleMenu: () => setMenuOpenState(!menuOpenState),
            stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
        }}>
            {props.children}
        </Context.Provider>
    )
}


const AnimatedComponent =React.memo(    function AnimatedComponent({ Component, ...rest }) {
    const animation = {
        initial: {
            scale: 0.8,
            opacity: 0,
            filter: "brightness(0.5) hue-rotate(-30deg) sepia(1.5) saturate(1.5)",
            transformOrigin: "center bottom",
            rotateY: 35,
            rotateX: -10,
            skew: "-12deg",
            perspective: 1500,
        },
        animate: {
            scale: 1,
            opacity: 1,
            filter: "brightness(1) hue-rotate(0deg) sepia(0) saturate(1)",
            rotateY: 0,
            rotateX: 0,
            skew: "0deg",
            transition: {
                scale: {
                    type: "spring",
                    stiffness: 100,
                    damping: 13,
                    ease: "easeInOut",
                    duration: 0.7,  // Reduced duration
                },
                opacity: {
                    duration: 1.2,  // Reduced duration
                    ease: "easeInOut",
                },
                filter: {
                    duration: 3,  // Reduced duration
                    ease: "easeInOut",
                },
                rotateY: {
                    duration: 1,  // Reduced duration
                    ease: "easeInOut",
                },
                rotateX: {
                    duration: 0.9,  // Reduced duration
                    ease: "easeInOut",
                },
                skew: {
                    duration: 0.8,  // Reduced duration
                    ease: "easeInOut",
                },
            }
        },
        exit: {
            scale: 0.7,
            opacity: 0,
            filter: "brightness(0.4) hue-rotate(30deg) sepia(1.5) saturate(1.2) blur(3px)",
            transformOrigin: "center bottom",
            rotateY: -30,
            rotateX: 10,
            skew: "12deg",
            perspective: 1500,
            transition: {
                duration: 1.3,  // Reduced duration
                ease: "easeInOut",
            }
        }
    };   return (
            <AnimatePresence>
                <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={animation}
                    style={sectionStyle} // Apply the sectionStyle here
                >
                    <Component {...rest} />
                </motion.div>
            </AnimatePresence>

        );
    }
);
export function Main({setVideoShown}) {
    const [message, setMessage] = useState(''); // State to hold message

    const [showHintBox, setShowHintBox] = useState(false);

    // Effect to show the hint box after a certain delay if the user is still in the 'intro' section

    useEffect(() => {
        if (location.pathname !== "/") {
            setVideoShown(true);
        }
        else{
            setVideoShown(false);

        }
    }, []);
    const [activeSection, setActiveSection] = useState(0);
    const sectionIds = ["", "intro", "education", "languagesView", "workExperiences", "projectCardsList", "skillPortfolio", "contactForm"];

    const navigate = useNavigate();
    const location = useLocation();



    useEffect(() => {
        const sectionIndex = sectionIds.indexOf(location.pathname.substr(1));
        if (sectionIndex !== -1) {
            setActiveSection(sectionIndex);
        }
    }, [location]);

    useEffect(() => {
        let hintTimeout;
        // For '/intro' path
        if (location.pathname === "/intro") {
            setMessage("Don't forget to explore more sections By Scrolling Down 👇!")
            hintTimeout = setTimeout(() => {
                setShowHintBox(true);
            }, 5000); // 5s delay
        }
        // For '/projectCardsList' path
        else if (location.pathname === "/projectCardsList") {
            setMessage("Please wait 🕒 we are loading the models 🚀...")
            hintTimeout = setTimeout(() => {
                setShowHintBox(true);
            }, 1000); // 1s delay
        }
        // For '/contactForm' path
        else if (location.pathname === "/contactForm") {
            setMessage("Thank you 🙏 for viewing my portfolio 🗂️. This is the end 🏁. But feel free to contact me 📧!")
            hintTimeout = setTimeout(() => {
                setShowHintBox(true);
            }, 1000); // 1s delay
        }
        else {
            setShowHintBox(false); // Hide hint box if user navigates away
        }

        // Set messages for HintBox depending on the path


        // Message state update and timeout cleanup logic
        return () => {
            clearTimeout(hintTimeout);
        };
    }, [location.pathname]);



    function navigateNext(){
    let newActiveSection = activeSection;
    newActiveSection = activeSection + 1;
    setActiveSection(newActiveSection);
    navigate('/' + sectionIds[newActiveSection]);
}

    const debounceTimeout = useRef(null); // timeout reference

    const handleScrollEvent = (yDir) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            let newActiveSection = activeSection;

            if (yDir > 0 && activeSection < sectionIds.length - 1) {
                newActiveSection = activeSection + 1;
            } else if (yDir < 0 && activeSection > 0) {
                newActiveSection = activeSection - 1;
            }

            setActiveSection(newActiveSection);
            navigate('/' + sectionIds[newActiveSection]);
        }, 150); // 150ms debounce time
    };

    const bind = useWheel(({ direction: [_, yDir] }) => {
        handleScrollEvent(yDir);
    });

    let startTouchY;

    //
    // const dragBind = useDrag(({ direction: [xDelta, yDelta], distance, velocity }) => {
    //     // Ignore horizontal swipes by checking if yDelta is much larger than xDelta
    //     if (Math.abs(yDelta) <= Math.abs(xDelta)) return;
    //
    //     const threshold = 0.5; // velocity threshold
    //     const distanceThreshold = 50; // distance threshold
    //
    //     if (velocity > threshold && distance > distanceThreshold) {
    //         handleScrollEvent(yDelta < 0 ? 1 : -1);
    //     }
    // }, {
    //     // Ensure event listeners target both touch and mouse
    //     eventOptions: { passive: false }
    // });

    const [startTouchPosition, setStartTouchPosition] = useState(null);
    const handleTouchStart = (e) => {
        setStartTouchPosition(e.touches[0].clientY);
    };

    const handleTouchEnd = (e) => {
        if (!startTouchPosition) return;

        const yDelta = e.changedTouches[0].clientY - startTouchPosition;
        const distance = Math.abs(yDelta);

        // Here, you can tweak the distance value to whatever you feel appropriate for triggering the action
        const distanceThreshold = 90;

        if (distance > distanceThreshold) {
            handleScrollEvent(yDelta < 0 ? 1 : -1);
        }

        setStartTouchPosition(null); // Reset the touch start position
    };


    return (
            <div className="main" id="outer-container"  {...bind()} style={{ overflowY: 'hidden' }}   onTouchStart={handleTouchStart}
                 onTouchEnd={handleTouchEnd}>
                <Suspense fallback={<div className={'loading-animation-container'}></div>}>
                    <Provider>
                        <Navigation context={Context} key={'key'} />
                    </Provider>
                        <div  id={'page-wrap'}>
                            {showHintBox && <HintBox message={message}  onClose={() => setShowHintBox(false)} />}

                            <Routes key={location.pathname}>
                        <Route path="/" element={<IntroAvatar setVideoShown={setVideoShown} navigateNext={navigateNext}/>} />
                        <Route path="/Intro" element={<AnimatedComponent Component={Intro} />} />
                        <Route path="/education" element={<AnimatedComponent Component={Education} />} />
                        <Route path="/languagesView" element={<AnimatedComponent Component={LanguagesView} />} />
                        <Route path="/workExperiences" element={<AnimatedComponent Component={WorkExperiences} />} />
                        <Route path="/projectCardsList" element={<AnimatedComponent Component={ProjectCardsList} projects={projects} />} />
                        <Route path="/skillPortfolio" element={<AnimatedComponent Component={SkillPortfolio} skillData={skills} />} />
                        <Route path="/contactForm" element={<AnimatedComponent Component={ContactForm} />} />
                        <Route path="*" element={<Navigate to="/" />} />

                    </Routes>
                        </div>
                </Suspense>


            </div>
    );
}




const sectionStyle = {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid transparent'

};




const introStyle={
    minHeight: '100vh',
    display: 'flex',
    flexDirection:'row',
    alignItems: 'center',

    border: '1px solid #e0e0e0'
}




